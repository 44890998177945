import React from 'react'
import { Link } from 'gatsby'
import { Location } from '@reach/router';
import classNames from 'classnames';
import logo from '../img/logo.png'

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            })
      }
    )
  }

  render() {
    const { links } = this.props;
    return (
      <nav
        className="navbar"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <Link to="/" className="navbar-item" title="Logo">
              <img src={logo} alt="Dottor Sica" style={{ height: 36 }} />
            </Link>
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <Location>
              {({ location }) => (
                <div className="navbar-start has-text-centered">
                  {links.map(link => {
                    const isSublinkEnabled = link.sublinks
                      && location.pathname.replace(/\//g, '') !== ''
                      && link.sublinks.filter(l => l.link.indexOf(location.pathname.replace(/\//g, '')) > -1).length > 0
                    return (
                      link.sublinks
                        ? (
                          <span key={link.link} className={classNames('navbar-item navbar-item--overable', { 'is--important': link.important, 'is--sub-selected': isSublinkEnabled })}>
                            <span>{link.text}</span>
                            <span className="icon">
                              <i className="fa fa-chevron-circle-down"></i>
                            </span>
                            {link.sublinks &&
                              <ul>
                                {link.sublinks.map((subLink) => (
                                  <Link key={subLink.link} className={classNames('navbar-item', { 'is--important': subLink.important })} to={/^\//.test(subLink.link) ? subLink.link : `/${subLink.link}`}>
                                    {subLink.text}
                                    {subLink.important && <i className="star" />}
                                  </Link>
                                ))}
                              </ul>
                            }
                          </span>
                        )
                        : (
                          <Link key={link.link} className={classNames('navbar-item', { 'is--important': link.important })} to={/^\//.test(link.link) ? link.link : `/${link.link}`}>
                            {link.text}
                          </Link>
                        )
                    )}
                  )}
                </div>
              )}
            </Location>
          </div>
        </div>
      </nav>
    )
  }
}

export default Navbar
