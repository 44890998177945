import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, StaticQuery, Link } from 'gatsby'
import { format } from 'date-fns'
import { it } from 'date-fns/locale'
import classNames from 'classnames';
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import useSiteMetadata from './SiteMetadata'

import './all.scss'

const TemplateWrapper = ({ children, links, secondarylinks, updatedon, numerotelefonico, numerotelefonicowhatsapp, orari, mappa, SEOTitle }) => {
  const { title, description } = useSiteMetadata()
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{SEOTitle ? `${SEOTitle} | ${title}` : title}</title>
        <meta name="description" content={description} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/img/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/favicon-32x32.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/favicon-16x16.png"
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href="/img/safari-pinned-tab.svg"
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta property="og:image" content="/img/og-image.jpg" />
        <link href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN" crossorigin="anonymous" />
        <script>
        {`
          var _iub = _iub || [];
          _iub.csConfiguration = {"lang":"it","siteId":1626887,"gdprAppliesGlobally":false,"cookiePolicyId":66930498, "banner":{ "acceptButtonDisplay":true,"customizeButtonDisplay":true,"position":"float-bottom-center" }};
        `}
        </script>
        <script type="text/javascript" src="//cdn.iubenda.com/cs/iubenda_cs.js" charset="UTF-8" async />
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-149770467-1" />
        <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'UA-149770467-1');
        `}
        </script>
      </Helmet>
      <div className="pre-navbar">
        <div className="container">
          <div className="pre-book">
            {
              secondarylinks.map(link => (
                <Link key={link.link} className={classNames({ 'is--important': link.important })} to={/^\//.test(link.link) ? link.link : `/${link.link}`}>
                  {link.text}
                </Link>
              ))
            }
          </div>
          <span className="book">
            Per prenotazioni: <a href={`tel:${numerotelefonico}`}>{numerotelefonico}</a> / <a href={`whatsapp:${numerotelefonicowhatsapp}`}>{numerotelefonicowhatsapp}</a>
          </span>
        </div>
      </div>
      <Navbar secondarylinks={secondarylinks} links={links} />
      <div>{children}</div>
      <Footer
        orari={orari}
        links={links}
        mappa={mappa}
        updatedon={format(new Date(), 'd MMMM y', { locale: it })}
        numerotelefonicowhatsapp={numerotelefonicowhatsapp}
      />
    </div>
  )
}

export default ({ children, title }) => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        index: markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
          frontmatter {
            numerotelefonico
            numerotelefonicowhatsapp
            links {
              text
              important
              link
              sublinks {
                text
                important
                link
              }
            }
            secondarylinks {
              text
              important
              link
            }
          }
        }
        contatti: markdownRemark(frontmatter: { templateKey: { eq: "contatti-page" } }) {
          frontmatter {
            orari {
              giorno
              mattina
              pomeriggio
            }
          }
        }
        dovesiamo: markdownRemark(frontmatter: { templateKey: { eq: "dove-siamo-page" } }) {
          frontmatter {
            mappa
          }
        }
      }
    `}
    render={(data) => {
      return (
        <TemplateWrapper
          {...data.index.frontmatter}
          orari={data.contatti.frontmatter.orari}
          mappa={data.dovesiamo.frontmatter.mappa}
          SEOTitle={title}
        >
          {children}
        </TemplateWrapper>
      );
    }}
  />
)
