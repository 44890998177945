import React from 'react'
import { Link } from 'gatsby'

import logo from '../img/logo.png'

const UpdatedOn = React.memo(({ updatedon }) => <p>Ultimo aggiornamento: {updatedon}</p>, () => true);

const Footer = ({ links, updatedon, orari, numerotelefonicowhatsapp, mappa }) => {
  return (
    <footer className="footer has-background-black has-text-white-ter">
      <div className="content has-text-centered has-background-black has-text-white-ter">
        <div className="container has-background-black has-text-white-ter">
          <div className="columns">
            <div className="column is-8" style={{ alignItems: 'center', display: 'flex' }}>
              <section className="menu">
                <ul className="menu-list-bottom">
                  {
                    links.filter(l => !l.sublinks).map(link => (
                      <li key={link.link}>
                        <Link to={/^\//.test(link.link) ? link.link : `/${link.link}`}>
                          {link.text}
                        </Link>
                      </li>
                    ))
                  }
                </ul>
              </section>
            </div>
            <div className="column is-4">
              <a title="facebook" href="https://fb.com/studidentisticisica">
                <span className="icon is-large">
                  <i className="fa fa-facebook fa-lg" />
                </span>
              </a>
              <a title="whatsapp" href={`whatsapp:${numerotelefonicowhatsapp}`}>
                <span className="icon is-large">
                  <i className="fa fa-whatsapp fa-lg" />
                </span>
              </a>
              <a title="instagram" href="https://instagram.com/studidentisticisica">
                <span className="icon is-large">
                  <i className="fa fa-instagram fa-lg" />
                </span>
              </a>
            </div>
          </div>
          <div className="is-size-12 has-text-weight-light footer-sub-container">
            <div className="footer-contatti">
              <img src={logo} alt="Dottor Sica" style={{ height: 50 }} />
              <p>
                Via Primo Maggio,16 – Tel. e Fax: <a href="tel:0499790099">049-9790099</a><br />
                35020 Sant’Angelo di Piove di Sacco<br />
                P.IVA: 03641110659<br />
                email: <a href="mailto:info@studiodentisticosica.it">info@studiodentisticosica.it</a>
              </p>
              <div className="container-gmaps-footer-wrapper">
                <Link to="/dove-siamo" className="container-gmaps-footer-link" />
                <div className="container-gmaps-footer" dangerouslySetInnerHTML={{ __html: mappa }} />
              </div>
            </div>
            <div className="footer-orari">
              <div className="orari-footer-wrapper">
                <div className="orario orario--footer">
                  <div className="orario-giorno"></div>
                  <div className="orario-mattina">Mattina</div>
                  <div className="orario-pomeriggio">Pomeriggio</div>
                </div>
                <div className="orari orari--footer">
                  {orari.map(orario => (
                    <div className="orario orario--footer" key={orario.giorno}>
                      <div className="orario-giorno">{orario.giorno}</div>
                      <div className="orario-mattina">{orario.mattina || '-'}</div>
                      <div className="orario-pomeriggio">{orario.pomeriggio || '-'}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div>
            <p>
              Il titolare dello studio dichiara che il sito corrisponde alle linee-guida inerenti l'applicazione degli art. 55-56-57 del codice di deontologia medica (pubblicità dell'informazione sanitaria). <br /> Autocertificazione inviata all' Ordine dei Medici Chirurghi e Odontoiatri di Padova in data 13/02/2008.
            </p>
            <UpdatedOn updatedon={updatedon} />
            <div className="has-text-centered" dangerouslySetInnerHTML={{ __html: `
              <a href="https://www.iubenda.com/privacy-policy/66930498" class="iubenda-white iubenda-embed" title="Privacy Policy ">Privacy Policy</a><script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script>
            ` }} />
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer
